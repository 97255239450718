let usuarioAutenticado = false;

export function verificarAutenticacion() {
  return usuarioAutenticado;
}

export function iniciarSesion() {
  usuarioAutenticado = true;
}

export function cerrarSesion() {
  usuarioAutenticado = false;
}
