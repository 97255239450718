import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import NotFound from "@/pages/NotFoundPage.vue";
import { verificarAutenticacion } from './auth';

const Consolidado = () => import("@/pages/Consolidado.vue");
const Login = () => import("@/pages/Login.vue");
const Comparativa = () => import("@/pages/comparativa.vue");
const Pmp = () => import("@/pages/pmp.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/login",
    children: [
      {
        path: "login",
        name: "login",
        component: Login
      },
      {
        path: "consolidado",
        name: "consolidado",
        component: Consolidado
      }
    ]
  },
  { path: "*", component: NotFound },
];

/*router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!verificarAutenticacion()) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});*/

export default routes;
