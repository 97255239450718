<template>
  <div class="wrapper">
    <side-bar v-show="esRutaConsolidado">>
      <template slot="links">
        <sidebar-link to="/consolidado" :name="$t('Consolidadp')" icon="tim-icons icon-puzzle-10"/>
        <!--<sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36"/>-->
        <!--<sidebar-link to="/meta" :name="$t('Reportes')" icon="tim-icons icon-puzzle-10"/>-->
        <!--
        <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02"/>
        <sidebar-link to="/table-list" :name="$t('sidebar.tableList')" icon="tim-icons icon-puzzle-10"/>
        <sidebar-link to="/dashboard?enableRTL=true" :name="$t('sidebar.rtlSupport')" icon="tim-icons icon-world"/>-->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar">
      </dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import Vue from 'vue';
import VueRouter from 'vue-router';
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
Vue.use(VueRouter);
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
    this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
  },
  },
  computed: {
    esRutaConsolidado() {
      return this.$route.path !== '/login';
    },
  },
};
</script>
