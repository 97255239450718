import VueRouter from "vue-router";
import routes from "./routes";
import { verificarAutenticacion } from './auth';
const router = new VueRouter({
  routes,
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!verificarAutenticacion()) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
